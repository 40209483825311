/** @format */

import React from "react";

const Details = () => {
  return (
    <>
      {/* =========================
		CONTACTS DETAILS
	============================== */}
      <div className="def-section contact-details">
        <div className="container">
          <div className="row">
            {/* === CONTACTS DETAILS ITEM === */}
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 contact-detail">
              <div className="contact-detail-icon">
                <i className="flaticon-map58" />
              </div>
              <div className="contact-detail-title">
                <h3>LOCATION</h3>
              </div>
              <div className="contact-detail-text">
                Adress: 20000, <br />
                Casablanca Morocco
              </div>
            </div>
            {/* === CONTACTS DETAILS ITEM === */}
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 contact-detail contact-detail-mark">
              <div className="contact-detail-icon">
                <i className="flaticon-telephone5" />
              </div>
              <div className="contact-detail-title">
                <h3>PHONE | EMAIL</h3>
              </div>
              <div className="contact-detail-text">
                +212 0711 650 603
                <br />
                Contact@beyond-expertise.com
              </div>
            </div>
            {/* === CONTACTS DETAILS ITEM === */}
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 contact-detail">
              <div className="contact-detail-icon">
                <i className="flaticon-clock96" />
              </div>
              <div className="contact-detail-title">
                <h3>WORKING HOURS</h3>
              </div>
              <div className="contact-detail-text">
                Mon - Fri : 08.00 17.00
                <br />
                Sat : 09.00 14.00
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* =========================
		END CONTACTS DETAILS
	============================== */}
    </>
  );
};

export default Details;
