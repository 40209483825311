/** @format */

import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      {/* ===================================
		FOOTER
	======================================== */}
      <footer className="def-section footer">
        <div className="container">
          <div className="row">
            {/* === FOOTER COLUMN === */}
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 footer-1">
              <div className="logo with-border-bottom">
                <div className="logo-image">
                  <img src="img/logo.png" alt="" />
                </div>
                <div className="logo-text">
                  L.M. <span className="color-primary">S</span>
                </div>
              </div>
              <div className="footer-1-text">
                <p>
                  Praesent vestibulum dapibus nibh. Etiam iaculis nunc ac metus.
                  Ut id nisl quis enim dignissim sagittis. Etiam sollicitudin,
                  ipsum pulvinar rutrum,tellus ipsum. laoreet{" "}
                </p>
              </div>
              <div className="footer-1-button">
                <Link to="/about">
                  <div className="my-btn my-btn-primary">
                    <div className="my-btn-bg-top" />
                    <div className="my-btn-bg-bottom" />
                    <div className="my-btn-text">MORE</div>
                  </div>
                </Link>
              </div>
            </div>
            {/* === FOOTER COLUMN === */}
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 footer-2">
              <h4 className="with-square with-border-bottom">LINKS</h4>

              <div className="footer-2-links">
                <div className="footer-2-links-1">
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/services">Service</Link>
                    </li>
                    <li>
                      <Link to="/about">About us</Link>
                    </li>
                    <li>
                      <Link to="/about">Testimonial</Link>
                    </li>
                    <li>
                      <Link to="/Contact">Contact</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* === FOOTER COLUMN === */}
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 footer-4">
              <h4 className="with-square with-border-bottom">ADRESS</h4>
              <div className="footer-3-phone footer-3-item">
                <span className="footer-3-icon">
                  <i className="fa fa-phone" />
                </span>{" "}
                Telephone: +212 0711 650 603
              </div>
              <div className="footer-3-fax footer-3-item">
                <span className="footer-3-icon">
                  <i className="fa fa-fax" />
                </span>{" "}
                Fax/phone: +212 0711 650 603
              </div>
              <div className="footer-3-mail footer-3-item">
                <span className="footer-3-icon">
                  <i className="fa fa-envelope" />
                </span>{" "}
                E-mail: Contact@beyond-expertise.com
              </div>
              <div className="footer-3-adress footer-3-item">
                <span className="footer-3-icon">
                  <i className="fa fa-map-marker" />
                </span>{" "}
                Adress: 20000, Casablanca Morocco
              </div>
            </div>
            {/* === FOOTER COLUMN === */}
          </div>
        </div>
      </footer>
      {/* ===================================
		END FOOTER
	======================================== */}
      {/* ===================================
		BOTTOM SECTION
	======================================== */}
      <div className="bottom">
        <div className="container">
          <div className="row">
            {/* === BOTTOM LEFT === */}
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 bottom-1">
              COPYRIGHT 2022 | L.M. <span className="color-primary">S</span>
            </div>
            {/* === BOTTOM CENTER === */}
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 bottom-2">
              {/* <a href="#">
                <div className="my-btn my-btn-grey">
                  <div className="my-btn-bg-top" />
                  <div className="my-btn-bg-bottom" />
                  <div className="my-btn-text">
                    <i className="fa fa-twitter" />
                  </div>
                </div>
              </a>
              <a href="#">
                <div className="my-btn my-btn-grey">
                  <div className="my-btn-bg-top" />
                  <div className="my-btn-bg-bottom" />
                  <div className="my-btn-text">
                    <i className="fa fa-facebook" />
                  </div>
                </div>
              </a>
              <a href="#">
                <div className="my-btn my-btn-grey">
                  <div className="my-btn-bg-top" />
                  <div className="my-btn-bg-bottom" />
                  <div className="my-btn-text">
                    <i className="fa fa-google-plus" />
                  </div>
                </div>
              </a>
              <a href="#">
                <div className="my-btn my-btn-grey">
                  <div className="my-btn-bg-top" />
                  <div className="my-btn-bg-bottom" />
                  <div className="my-btn-text">
                    <i className="fa fa-pinterest-p" />
                  </div>
                </div>
              </a>
              <a href="#">
                <div className="my-btn my-btn-grey">
                  <div className="my-btn-bg-top" />
                  <div className="my-btn-bg-bottom" />
                  <div className="my-btn-text">
                    <i className="fa fa-instagram" />
                  </div>
                </div>
              </a> */}
            </div>
            {/* === BOTTOM RIGHT === */}
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 bottom-3">
              <a href="#foo">TERM OF USE</a> | MADE BY{" "}
              <a href="https://beyond-expertise.com/" target="_">
                Beyond <span className="color-primary">Expertise</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* ===================================
		END BOTTOM SECTION
	======================================== */}
    </>
  );
};

export default Footer;
