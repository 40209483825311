/** @format */

import React, { useRef } from "react";
import CountUp from "react-countup";
import useOnScreen from "../useOnScreen";

const Stats = () => {
  const ref = useRef();
  const isVisible = useOnScreen(ref);
  return (
    <>
      {/* ===================================
		SECTION STATS
	======================================== */}
      <div
        className="def-section stats"
        id="home-stats"
        data-stellar-background-ratio="0.4">
        <div className="stats-overlay" />
        <div className="container">
          <div className="row">
            {/* === STATS ITEM === */}
            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
              <div className="stat-item">
                <div className="stat-item-icon">
                  <i className="flaticon-first33" />
                </div>
                <div className="stat-item-number" id="num1" ref={ref}>
                  {isVisible && <CountUp duration={1} end={450} />}
                </div>
                <div className="stat-item-text">
                  PROJECTS
                  <br />
                  DONE
                </div>
              </div>
            </div>
            {/* === STATS ITEM === */}
            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
              <div className="stat-item stat-item-mark">
                <div className="stat-item-icon">
                  <i className="flaticon-shirt16" />
                </div>
                <div className="stat-item-number" id="num2">
                  {isVisible && <CountUp duration={1} end={150} />}
                </div>
                <div className="stat-item-text">
                  CLIENTS
                  <br />
                  HAPPY
                </div>
              </div>
            </div>
            {/* === STATS ITEM === */}
            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
              <div className="stat-item">
                <div className="stat-item-icon">
                  <i className="flaticon-group2" />
                </div>
                <div className="stat-item-number" id="num3">
                  {isVisible && <CountUp duration={1} end={120} />}
                </div>
                <div className="stat-item-text">
                  WORKERS
                  <br />
                  TEAM
                </div>
              </div>
            </div>
            {/* === STATS ITEM === */}
            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
              <div className="stat-item">
                <div className="stat-item-icon">
                  <i className="flaticon-clock96" />
                </div>
                <div className="stat-item-number" id="num4">
                  {isVisible && <CountUp duration={1} end={100} />}
                </div>
                <div className="stat-item-text">
                  YEARS IN
                  <br />
                  MARKET
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ===================================
		END SECTION STATS
	======================================== */}
    </>
  );
};

export default Stats;
