/** @format */

import React from "react";
import { Link } from "react-router-dom";
import ship from "../../assets/media/about/ship.png";
const About = () => {
  return (
    <>
      {/* ===================================
		SECTION ABOUT US AND GET QUOTE
	======================================== */}
      <section className="def-section about-quote">
        <div className="container">
          <div className="row">
            {/* === ABOUT US === */}
            <div className="col-lg-5 col-md-5 col-sm-5 col-xs-12 home-about ">
              {/* === TITLE GROUP === */}
              <div className="title-group">
                <h2>ABOUT US</h2>
                <div className="subtitle with-square">
                  LMS (Laayoune Marine Survey)
                </div>
              </div>
              {/* === ABOUT US TEXT === */}
              <p className="position-relative"></p>
              <div className="service-mark-border-left" />
              Global Surveyors&amp; Inspectors Ltd (GSI) is a government
              licensed survey company established in 1980 to offer and carry out
              highly professional marine surveys and inspection services to
              shipowners ...
              <p />
              <div className="service-button">
                <Link to="/about">
                  <div className="my-btn my-btn-default">
                    <div className="my-btn-bg-top" />
                    <div className="my-btn-bg-bottom" />
                    <div className="my-btn-text">MORE</div>
                  </div>
                </Link>
              </div>
            </div>
            {/* === GET QUOTE === */}
            <div className="col-lg-7 col-md-7 col-sm-7 col-xs-12 get-quote">
              <div className="">
                <img src={ship} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ===================================
		END SECTION ABOUT US AND GET QUOTE
	======================================== */}
    </>
  );
};

export default About;
