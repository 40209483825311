/** @format */

export const SliderData = [{
        image: "/sliders/slider.jpg",
    },
    {
        image: "/sliders/slider2.jpg",
    },
    {
        image: "/sliders/slider3.jpg",
    },
];