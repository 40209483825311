/** @format */

import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";
import * as React from "react";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
  fontSize: "2rem",
}));

export default function CustomizedAccordions() {
  const [expanded, setExpanded] = React.useState("");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div style={{ padding: "0 10%", marginBottom: "50px" }}>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <p style={{ fontSize: "2rem" }}>Vision</p>
        </AccordionSummary>
        <AccordionDetails>
          <p style={{ fontSize: "2rem" }}>
            Laayoune Marine Survey was established in 2010 with a mission to
            provide good quality and reliable service to all our clients..
          </p>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <p style={{ fontSize: "2rem" }}>Mission</p>{" "}
        </AccordionSummary>
        <AccordionDetails>
          <p style={{ fontSize: "2rem" }}>
            Laayoune Marine Survey strives to be a trusted international
            inspections company and offers a wide range of quantity, quality
            checks and related services.{" "}
          </p>
          <p style={{ fontSize: "2rem" }}>
            Our services are designed to facilitate the safe movement of goods,
            avoiding any complications resulting from improper handling and
            post-delivery problem.
          </p>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <p style={{ fontSize: "2rem" }}>Commitment</p>{" "}
        </AccordionSummary>
        <AccordionDetails>
          <p style={{ fontSize: "2rem" }}>
            Laayoune Marine Survey has a commitment to achieve Customer
            satisfaction and Continual improvement. We pride ourselves on the
            core values of Teamwork, Professionalism and Business Ethics.
          </p>
          <p style={{ fontSize: "2rem" }}>
            We are strongly committed to conduct ourselves professionally
            offering excellent customer service to our valued clients. .
          </p>
          <p style={{ fontSize: "2rem" }}>
            We will ensure that all information regarding the service and
            inspection is relayed promptly and efficiently.
          </p>
          <p style={{ fontSize: "2rem" }}>
            The highest level of quality will be maintained for our services and
            at the same time ensure that all safety standards are met or
            exceeded.
          </p>
          <p style={{ fontSize: "2rem" }}>
            We will ensure that our service is of the highest order and will
            benchmark key elements of our service with leading industry and
            international standards.
          </p>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
