/** @format */

import React from "react";

const AboutText = () => {
  return (
    <>
      {/* =========================
		ABOUT TEXT
	============================== */}
      <div className="def-section about-text">
        <div className="container">
          <div className="about-text-image">
            <img src="media/about/1.jpg" alt="" />
          </div>
          <h2>Global Surveyors&amp; Inspectors Ltd (GSI)</h2>
          <p>
            is a government licensed survey company established in 1980 to offer
            and carry out highly professional marine surveys and inspection
            services to shipowners, charterers, multimodal carriers, marine
            underwriters, maritime lawyers and any of those who is in need of
            the services.
            <br /> Another important function of GSI is the independent
            assessment of quality and quantity of commodity, manufactured goods
            and raw materials in trade through inspection and testing on the
            basis of neutral and unbiased position for traders.
            <br /> GSI maintains branch offices in the major ports of Busan,
            Inchon, Ulsan and Yosu with head office in Seoul and all offices are
            staffed with highly experienced and qualified surveyors who have at
            least five years of intensive activity in the inspec- tion field.
            <br /> GSI offices are linked with efficient communication line and
            our services can be called upon 24 hours a day, 7 daysa week and 365
            days a year upon our clients' call.
          </p>
        </div>
      </div>
      {/* =========================
		END ABOUT TEXT
	============================== */}
    </>
  );
};

export default AboutText;
