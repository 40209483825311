/** @format */

import React from "react";
import About from "../Components/Home/About";
import Clients from "../Components/Home/Clients";
import CustomizedAccordions from "../Components/Home/CustomizedAccordions";
import Services from "../Components/Home/Services";
import Slider from "../Components/Home/Slider";
import Stats from "../Components/Home/Stats";

const Home = () => {
  return (
    <div>
      <Slider />
      <Services />
      <About />
      <CustomizedAccordions />
      <Stats />
      <Clients />
    </div>
  );
};

export default Home;
