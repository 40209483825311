/** @format */

import React from "react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import ImageSlider from "../ImageSlider";
import { SliderData } from "../SliderData";

const Slider = () => {
  return (
    <>
      {/* =========================
		MAIN SLIDER
	============================== */}
      <div className="">
        <ImageSlider slides={SliderData} />
      </div>
      {/* =========================
		END MAIN SLIDER
	============================== */}
    </>
  );
};

export default Slider;
