/** @format */

import React from "react";
import about from "../assets/media/about/about.jpg";
import AboutText from "../Components/AboutText";
import Accordion from "../Components/Home/CustomizedAccordions";
import Header from "../Components/Services/Header";
const About = () => {
  return (
    <div>
      <Header page="About" image={about} />
      <AboutText image={about} />
      <Accordion />
    </div>
  );
};

export default About;
