/** @format */

import React from "react";
import service from "../assets/media/service/transport.jpg";
import Header from "../Components/Services/Header";
import ServicesGroup from "../Components/Services/ServicesGroup";

const Services = () => {
  return (
    <div>
      <Header page="Services" image={service} />
      <ServicesGroup />
    </div>
  );
};

export default Services;
