/** @format */

import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/img/logo.png";
const Navbar = ({ toggle }) => {
  const [url, setUrl] = useState(window.location.pathname);
  return (
    <>
      {/* =========================
		TOP MAIN NAVBAR
	============================== */}
      <div
        className="main-navbar main-navbar-2"
        id="main-navbar"
        style={{
          width: "100%",
          background: "white",
          zIndex: 99 /* top: 0, */,
          position: "fixed",
        }}>
        <div className="container">
          <div className="row">
            {/* === TOP LOGO === */}
            <Link to="/" onClick={() => setUrl("/")}>
              <div className="logo" id="main-logo">
                <div className="logo-image">
                  <img src={logo} alt="" />
                </div>
                <div className="logo-text">
                  L.M. <span className="color-primary">S</span>
                </div>
              </div>
            </Link>
            <div className="show-menu-control">
              {/* === top search button show === */}
              <a
                id="show-menu"
                className="show-menu"
                href="#foo"
                onClick={toggle}>
                <div className="my-btn my-btn-primary">
                  <div className="my-btn-bg-top" />
                  <div className="my-btn-bg-bottom" />
                  <div className="my-btn-text">
                    <i className="fa fa-bars" />
                  </div>
                </div>
              </a>
            </div>
            {/* === TOP MENU === */}
            <div
              className="collapse navbar-collapse main-menu main-menu-2"
              id="main-menu"
              style={{ paddingTop: 10 }}>
              <ul className="nav navbar-nav">
                {/* === top menu item === */}
                <li
                  className={url === "/" && "active"}
                  onClick={() => setUrl("/")}>
                  <Link to="/">Home</Link>
                </li>
                <li className="main-menu-separator" />
                {/* === top menu item === */}
                <li
                  className={url === "/services" && "active"}
                  onClick={() => setUrl("/services")}>
                  <Link to="/services">Service</Link>
                </li>
                <li className="main-menu-separator" />
                {/* === top menu item === */}
                <li
                  className={url === "/about" && "active"}
                  onClick={() => setUrl("/about")}>
                  <Link to="/about">About us</Link>
                </li>
                <li className="main-menu-separator" />
                <li className="main-menu-separator" />
                {/* === top menu item === */}
                <li
                  className={url === "/Contact" && "active"}
                  onClick={() => setUrl("/Contact")}>
                  <Link className="latest" to="/Contact">
                    Contacts
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* =========================
		END TOP MAIN NAVBAR
	============================== */}
    </>
  );
};

export default Navbar;
