/** @format */

import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import "./assets/master.css";
import Footer from "./Components/Footer";
import Navbar from "./Components/Navbar";
import ScrollToTop from "./Components/ScrollToTop";
import SideNavbar from "./Components/SideNavbar";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import Home from "./Pages/Home";
import ServiceDetail from "./Pages/ServiceDetail";
import Services from "./Pages/Services";
const App = () => {
  const [mobileNav, setmobileNav] = useState(false);
  const toggle = () => {
    setmobileNav(!mobileNav);
  };

  return (
    <div>
      <SideNavbar mobileNav={mobileNav} toggle={toggle} />
      <Navbar toggle={toggle} />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/services/:service" element={<ServiceDetail />} />
        <Route path="/about" element={<About />} />
        <Route path="/Contact" element={<Contact />} />
      </Routes>
      <Footer />
    </div>
  );
};

export default App;
