/** @format */

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import data from "../../assets/json/services.json";
import ServiceMenu from "./ServiceMenu";
const ServiceSingle = () => {
  const { service } = useParams();
  const [url, seturl] = useState(service);
  const [serviceData, setServiceData] = useState({});
  useEffect(() => {
    data.map((item) => {
      if (item.url === url) {
        setServiceData(item);
        return item;
      }
      return null;
    });
  }, [url]);
  const serviceHandler = (service) => {
    seturl(service);
  };
  if (serviceData) {
    return (
      <div className="def-section services-detail">
        <div className="container">
          <div className="row">
            <ServiceMenu url={url} seturl={serviceHandler} />

            {/* === SERVICE ITEM DESCRIPTION === */}
            <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
              <h2>{serviceData.name}</h2>
              {serviceData.details &&
                serviceData.details.map((item) => {
                  return (
                    <div className="service-detail">
                      {/* === SERVICE ITEM TITLE === */}
                      {/* === SERVICE ITEM IMAGE === */}
                      <div className="service-detail-bigimage">
                        <img src={item.image} alt={item.title} />
                      </div>
                      <div className="service-related-title">
                        <h3>
                          <a href="#foo">{item.title}</a>
                        </h3>
                      </div>
                      <div className="row">
                        {/* === SERVICE RELATED ITEM === */}
                        {item.side1 && (
                          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="service-related">
                              <div className="service-related-text">
                                <ul>
                                  {item.side1.map((list) => {
                                    return (
                                      <li>
                                        <p style={{ fontSize: "25px" }}>
                                          {list}
                                        </p>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                          </div>
                        )}
                        {item.side2 && (
                          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="service-related">
                              <div className="service-related-text">
                                <ul>
                                  {item.side2.map((list) => {
                                    return (
                                      <li>
                                        <p style={{ fontSize: "25px" }}>
                                          {list}
                                        </p>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                          </div>
                        )}
                        {item.side4 && (
                          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="service-related">
                              <div className="service-related-text">
                                {item.side4.map((is) => {
                                  return (
                                    <p style={{ fontSize: "25px" }}>{is}</p>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        )}
                        {item.side3 && (
                          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="service-related">
                              <div className="service-related-text">
                                <ul>
                                  {item.side3.map((is) => {
                                    return (
                                      <li>
                                        <p style={{ fontSize: "25px" }}>
                                          {is.text}
                                        </p>
                                        <ul>
                                          {is.list &&
                                            is.list.map((list) => {
                                              return (
                                                <li>
                                                  <p
                                                    style={{
                                                      fontSize: "20px",
                                                    }}>
                                                    {list}
                                                  </p>
                                                </li>
                                              );
                                            })}
                                        </ul>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <div>Loading...</div>;
  }
};

export default ServiceSingle;
