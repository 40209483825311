/** @format */

import emailjs from "@emailjs/browser";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

const Form = () => {
  const form = useRef();
  const navigate = useNavigate();

  const [error, seterror] = useState("");
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_55xmafq",
        "template_54cq72q",
        form.current,
        "H_mNpN2G4dDn-sFXJ"
      )
      .then(
        (result) => {
          // redirect to /
          navigate("/");
        },
        (error) => {
          seterror(error.text);
        }
      );
  };
  return (
    <>
      {/* =========================
		CONTACTS
	============================== */}
      <div className="def-section">
        <div className="container">
          <div className="row">
            {/* === CONTACTS INFO === */}
            <div className="col-lg-5 col-md-5 col-sm-5 col-xs-12">
              <div className="contacts-info">
                <div className="contacts-info-title">
                  <div className="contacts-info-title-icon">
                    <i className="fa fa-comments" />
                  </div>
                  <h3>
                    We will be glad
                    <br />
                    to help you
                  </h3>
                </div>
                <div className="contacts-info-text">
                  Etiam rhoncus. Maecenas tempus, tellus eget condimentum
                  rhoncus, sem quam semper libero, sit amet adipiscing sem neque
                  sed ipsum. Nam quam nunc, blandit vel, luctus pulvinar.
                </div>
                {/* <div className="contacts-info-people">
                  <div className="contacts-info-people-avatar">
                    <img src="media/avatars/avatar1.png" alt="" />
                  </div>
                  <div className="contacts-info-people-name">RICHARD GEAR</div>
                  <div className="contacts-info-people-company">Director</div>
                </div> */}
              </div>
            </div>
            {/* === CONTACTS FORM === */}
            <div className="col-lg-7 col-md-7 col-sm-7 col-xs-12">
              <div className="contacts-form row">
                <div className="send-result" />
                <h2 className="error">{error}</h2>
                <form
                  name="contact-form"
                  ref={form}
                  id="contact-form"
                  method="POST"
                  onSubmit={sendEmail}>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 contacts-form-item">
                    <input
                      type="text"
                      name="user_name"
                      placeholder="Full Name"
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 contacts-form-item">
                    <input type="text" name="user_email" placeholder="Email" />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 contacts-form-item">
                    <input type="text" name="user_phone" placeholder="Phone" />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 contacts-form-item">
                    <input
                      type="text"
                      name="user_company"
                      placeholder="Company"
                    />
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 contacts-form-item">
                    <textarea name="message" placeholder="How can we help?" />
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 contacts-form-item contacts-form-button">
                    <button>
                      <span className="my-btn my-btn-grey">
                        <span className="my-btn-bg-top" />
                        <span className="my-btn-bg-bottom" />
                        <span className="my-btn-text">SEND MESSAGE</span>
                      </span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Form;
