/** @format */

import React from "react";
import { Link } from "react-router-dom";

const Services = () => {
  return (
    <>
      {/* =========================
		SERVICES
	============================== */}
      <section className="def-section home-services">
        <div className="container">
          <div className="row" id="service-cont">
            {/* === SERVICE ITEM === */}
            <div
              className="col-lg-4 col-md-4 col-sm-4 col-xs-12 service-btn "
              id=""
              onMouseEnter={(e) => {
                e.currentTarget.classList.add("service-mark");
              }}
              onMouseLeave={(e) => {
                e.currentTarget.classList.remove("service-mark");
              }}>
              <div className="service">
                <div className="service-icon">
                  <i className="fas fa-poll-h" />
                </div>
                <h3>Marine Survey</h3>
                <div className="service-text">
                  <p>
                    Laayoune Marine Survey was established in 2010 with a
                    mission to provide good quality and reliable service to all
                    our clients.
                  </p>
                </div>
                <div className="service-button">
                  <Link to="/services/marine_survey">
                    <div className="my-btn my-btn-default">
                      <div className="my-btn-bg-top" />
                      <div className="my-btn-bg-bottom" />
                      <div className="my-btn-text">MORE</div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            {/* === SERVICE ITEM === */}
            <div
              className="col-lg-4 col-md-4 col-sm-4 col-xs-12 service-btn "
              onMouseEnter={(e) => {
                e.currentTarget.classList.add("service-mark");
              }}
              onMouseLeave={(e) => {
                e.currentTarget.classList.remove("service-mark");
              }}>
              <div className="service">
                <div className="service-icon">
                  <i className="fas fa-vials" />
                </div>
                <h3>Commodity Inspection</h3>
                <div className="service-text">
                  <p>
                    Laayoune Marine Survey was established in 2010 with a
                    mission to provide good quality and reliable service to all
                    our clients.
                  </p>
                </div>
                <div className="service-button">
                  <Link to="/services/commodity_inspection">
                    <div className="my-btn my-btn-default">
                      <div className="my-btn-bg-top" />
                      <div className="my-btn-bg-bottom" />
                      <div className="my-btn-text">MORE</div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            {/* === SERVICE ITEM === */}
            <div
              className="col-lg-4 col-md-4 col-sm-4 col-xs-12 service-btn"
              onMouseEnter={(e) => {
                e.currentTarget.classList.add("service-mark");
              }}
              onMouseLeave={(e) => {
                e.currentTarget.classList.remove("service-mark");
              }}
              id="">
              <div className="service">
                <div className="service-icon">
                  <i className="fas fa-poll" />{" "}
                </div>
                <h3>PRE-SHIPMENT IINSPECTION (PSI)</h3>
                <div className="service-text">
                  <p>
                    Laayoune Marine Survey was established in 2010 with a
                    mission to provide good quality and reliable service to all
                    our clients.
                  </p>
                </div>
                <div className="service-button">
                  <Link to="/services/psi">
                    <div className="my-btn my-btn-default">
                      <div className="my-btn-bg-top" />
                      <div className="my-btn-bg-bottom" />
                      <div className="my-btn-text">MORE</div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            {/* === SERVICE ITEM === */}
            <div
              className="col-lg-4 col-md-4 col-sm-4 col-xs-12 service-btn"
              onMouseEnter={(e) => {
                e.currentTarget.classList.add("service-mark");
              }}
              onMouseLeave={(e) => {
                e.currentTarget.classList.remove("service-mark");
              }}
              id="">
              <div className="service">
                <div className="service-icon">
                  <i className="fas fa-vial" />{" "}
                </div>
                <h3>OIL &amp;PRODUCT INSPECTION</h3>
                <div className="service-text">
                  <p>
                    Laayoune Marine Survey was established in 2010 with a
                    mission to provide good quality and reliable service to all
                    our clients.
                  </p>
                </div>
                <div className="service-button">
                  <Link to="/services/product_inspection">
                    <div className="my-btn my-btn-default">
                      <div className="my-btn-bg-top" />
                      <div className="my-btn-bg-bottom" />
                      <div className="my-btn-text">MORE</div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            {/* === SERVICE ITEM === */}
            <div
              className="col-lg-4 col-md-4 col-sm-4 col-xs-12 service-btn"
              onMouseEnter={(e) => {
                e.currentTarget.classList.add("service-mark");
              }}
              onMouseLeave={(e) => {
                e.currentTarget.classList.remove("service-mark");
              }}
              id="">
              <div className="service">
                <div className="service-icon">
                  <i className="fas fa-hard-hat" />{" "}
                </div>
                <h3>BUNKER SURVEY</h3>
                <div className="service-text">
                  <p>
                    Laayoune Marine Survey was established in 2010 with a
                    mission to provide good quality and reliable service to all
                    our clients.
                  </p>
                </div>
                <div className="service-button">
                  <Link to="/services/bunker_survey">
                    <div className="my-btn my-btn-default">
                      <div className="my-btn-bg-top" />
                      <div className="my-btn-bg-bottom" />
                      <div className="my-btn-text">MORE</div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            {/* === SERVICE ITEM === */}
            <div
              className="col-lg-4 col-md-4 col-sm-4 col-xs-12 service-btn"
              onMouseEnter={(e) => {
                e.currentTarget.classList.add("service-mark");
              }}
              onMouseLeave={(e) => {
                e.currentTarget.classList.remove("service-mark");
              }}
              id="">
              <div className="service">
                <div className="service-icon">
                  <i className="fas fa-truck-loading" />{" "}
                </div>
                <h3>HEAVY CARGO LOADING</h3>
                <div className="service-text">
                  <p>
                    Laayoune Marine Survey was established in 2010 with a
                    mission to provide good quality and reliable service to all
                    our clients.
                  </p>
                </div>
                <div className="service-button">
                  <Link to="/services/heavy_cargo">
                    <div className="my-btn my-btn-default">
                      <div className="my-btn-bg-top" />
                      <div className="my-btn-bg-bottom" />
                      <div className="my-btn-text">MORE</div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* =========================
		END SERVICES
	============================== */}
    </>
  );
};

export default Services;
