/** @format */

import React from "react";
import service from "../assets/media/service/transport.jpg";
import Header from "../Components/Services/Header";
import ServiceSingle from "../Components/Services/ServiceSingle";

const ServiceDetail = () => {
  return (
    <div>
      <Header page="Service" image={service} />
      <ServiceSingle />
    </div>
  );
};

export default ServiceDetail;
