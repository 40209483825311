/** @format */

import React from "react";
import { Link } from "react-router-dom";

const ServiceMenu = ({ url, seturl }) => {
  return (
    <>
      {/* === SERVICES MENU === */}
      <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
        <ul className="service-menu">
          <li className={!url && "active"} onClick={() => seturl(null)}>
            <Link to="/services">ALL SERVICES</Link>
          </li>
          <li
            className={url === "marine_survey" && "active"}
            onClick={() => seturl("marine_survey")}>
            <Link to="/services/marine_survey">Marine Survey</Link>
          </li>
          <li
            className={url === "commodity_inspection" && "active"}
            onClick={() => seturl("commodity_inspection")}>
            <Link to="/services/commodity_inspection">
              Commodity Inspection
            </Link>
          </li>
          <li
            className={url === "psi" && "active"}
            onClick={() => seturl("psi")}>
            <Link to="/services/psi">Pre-Shipment Iinspection (PSI)</Link>
          </li>
          <li
            className={url === "product_inspection" && "active"}
            onClick={() => seturl("product_inspection")}>
            <Link to="/services/product_inspection">
              Oil &amp;Product Inspection
            </Link>
          </li>
          <li
            className={url === "bunker_survey" && "active"}
            onClick={() => seturl("bunker_survey")}>
            <Link to="/services/bunker_survey">Bunker Survey</Link>
          </li>
          <li
            className={url === "heavy_cargo" && "active"}
            onClick={() => seturl("heavy_cargo")}>
            <Link to="/services/heavy_cargo">Heavy Cargo Loading</Link>
          </li>
        </ul>
      </div>
    </>
  );
};

export default ServiceMenu;
