/** @format */

import React from "react";
import Client from "./Client.json";

const Clients = () => {
  return (
    <>
      {/*CLIENT START*/}
      <section className="client home_page2" id="client">
        <div className="perelaxBg5" />
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="sectionTitle bigTitle3">
                <h2 className="title mt-5 mt-lg-0">
                  Pour qui nous avons travaillé
                </h2>
                <h2 className="lead">Notre expérience client</h2>
              </div>
            </div>
          </div>
          <div className="row">
            {Client.map((client) => {
              return (
                <div
                  className="col-lg-3 col-sm-4 margin-bottom-5"
                  key={client.name}>
                  <div className="singleClient">
                    <a href="#foo">
                      <img src={client.image} alt={client.name} />
                    </a>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      {/*CLIENT END*/}
    </>
  );
};

export default Clients;
