/** @format */

import React from "react";
import contact from "../assets/media/contact/contact.jpg";
import Details from "../Components/Contact/Details";
import Form from "../Components/Contact/Form";
import Header from "../Components/Services/Header";

const Contact = () => {
  return (
    <div>
      <Header page="Contact" image={contact} />
      <Form />
      <Details />
      <>
        {/* =========================
		CONTACTS MAP
	============================== */}
        <div className="contact-map" id="contact-map"></div>
        {/* =========================
		END CONTACTS MAP
	============================== */}
      </>
    </div>
  );
};

export default Contact;
