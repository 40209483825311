/** @format */

import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import serv1 from "../../assets/media/service/1.jpg";
import serv2 from "../../assets/media/service/2.jpg";
import serv3 from "../../assets/media/service/3.jpg";
import serv4 from "../../assets/media/service/4.jpg";
import serv5 from "../../assets/media/service/5.jpg";
import serv6 from "../../assets/media/service/6.jpg";
import ServiceMenu from "./ServiceMenu";
const ServicesGroup = () => {
  const { service } = useParams();
  const [url, seturl] = useState(service);
  const serviceHandler = (service) => {
    seturl(service);
  };

  return (
    <>
      {/* =========================
		SERVICES
	============================== */}
      <div className="def-section services-1">
        <div className="container">
          <div className="row">
            <ServiceMenu url={url} seturl={serviceHandler} />
            {/* === SERVICES ITEMS === */}
            <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
              <div className="row">
                {/* === SERVICE ITEM === */}
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                  <div className="service-1">
                    <Link
                      to="/services/marine_survey"
                      className="service-1-image">
                      <img src={serv1} alt="" />
                    </Link>
                    <div className="service-1-title">
                      <Link to="/services/marine_survey">
                        <h3>Marine Survey</h3>
                      </Link>
                    </div>
                  </div>
                </div>
                {/* === SERVICE ITEM === */}
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                  <div className="service-1">
                    <Link
                      to="/services/commodity_inspection"
                      className="service-1-image">
                      <img src={serv2} alt="" />
                    </Link>
                    <div className="service-1-title">
                      <Link to="/services/commodity_inspection">
                        <h3>Commodity Inspection</h3>
                      </Link>
                    </div>
                  </div>
                </div>
                {/* === SERVICE ITEM === */}
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                  <div className="service-1">
                    <Link to="/services/psi" className="service-1-image">
                      <img src={serv3} alt="" />
                    </Link>
                    <div className="service-1-title">
                      <Link to="/services/psi">
                        <h3>Pre-Shipment Iinspection (PSI)</h3>
                      </Link>
                    </div>
                  </div>
                </div>
                {/* === SERVICE ITEM === */}
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                  <div className="service-1">
                    <Link
                      to="/services/product_inspection"
                      className="service-1-image">
                      <img src={serv4} alt="" />
                    </Link>
                    <div className="service-1-title">
                      <Link to="/services/product_inspection">
                        <h3>Oil &amp;Product Inspection</h3>
                      </Link>
                    </div>
                  </div>
                </div>
                {/* === SERVICE ITEM === */}
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                  <div className="service-1">
                    <Link
                      to="/services/bunker_survey"
                      className="service-1-image">
                      <img src={serv5} alt="" />
                    </Link>
                    <div className="service-1-title">
                      <h3>
                        <Link to="/services/bunker_survey">Bunker Survey</Link>
                      </h3>
                    </div>
                  </div>
                </div>
                {/* === SERVICE ITEM === */}
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                  <div className="service-1">
                    <Link
                      to="/services/heavy_cargo"
                      className="service-1-image">
                      <img src={serv6} alt="" />
                    </Link>
                    <div className="service-1-title">
                      <Link to="/services/heavy_cargo">
                        <h3>Heavy Cargo Loading</h3>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicesGroup;
