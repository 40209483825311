/** @format */

import React, { useState } from "react";
import { Link } from "react-router-dom";

const SideNavbar = ({ mobileNav, toggle }) => {
  const [url, setUrl] = useState(window.location.pathname);

  return (
    <>
      {/* =========================
	   SLIDE MENU
	============================== */}
      <aside
        id="slide-menu"
        className="slide-menu"
        style={{ left: mobileNav ? 0 : "-290px" }}>
        {/* === CLOSE MENU BUTON === */}
        <div className="close-menu" id="close-menu">
          <i className="fa fa-close" />
        </div>
        {/* === SLIDE MENU === */}
        <ul id="left-menu" className="left-menu">
          {/* === SLIDE MENU ITEM === */}
          <li onClick={() => setUrl("/")}>
            <Link to="/">Home </Link>
          </li>
          {/* === SLIDE MENU ITEM === */}
          <li
            className={url === "/services" && "active"}
            onClick={() => setUrl("/services")}>
            <Link to="/services">Service </Link>
          </li>
          {/* === SLIDE MENU ITEM === */}
          <li
            className={url === "/about" && "active"}
            onClick={() => setUrl("/about")}>
            <Link to="/about">About us</Link>
          </li>
          {/* === SLIDE MENU ITEM === */}
          <li
            className={url === "/Contact" && "active"}
            onClick={() => setUrl("/Contact")}>
            <Link to="/Contact">Contacts</Link>
          </li>
        </ul>
      </aside>
      {/* =========================
	   END SLIDE MENU
	============================== */}
      {/* =========================
	   BLACK OVERLAY
	============================== */}
      <div
        className="black-overlay"
        id="black-overlay"
        style={{
          display: mobileNav ? "block" : "none",
          opacity: mobileNav ? 1 : 0,
        }}
        onClick={toggle}
      />
      {/* =========================
	   END BLACK OVERLAY
	============================== */}
    </>
  );
};

export default SideNavbar;
