/** @format */

import React from "react";
import { Link } from "react-router-dom";

const Header = ({ page, image }) => {
  return (
    <>
      {/* ===================================
		PAGE HEADER
	======================================== */}
      <div
        className="page-header"
        data-stellar-background-ratio="0.8"
        style={{ backgroundImage: `url(${image})` }}>
        <div className="page-header-overlay" />
        <div className="container">
          <div className="row">
            {/* === PAGE HEADER TITLE === */}
            <div className="page-header-title">
              <h2>{page}</h2>
            </div>
            {/* === PAGE HEADER BREADCRUMB === */}
            <div className="page-header-breadcrumb">
              <ol className="breadcrumb">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="active">{page}</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      {/* ===================================
		END PAGE HEADER
	======================================== */}
    </>
  );
};

export default Header;
